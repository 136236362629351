import { upperFirst } from 'lodash-es';
import {
  CAREGIVER_QUALITIES,
  CAREGIVER_SERVICES,
  JOB_PREFERENCES,
  MERCHANDIZED_JOB_INTERESTS,
} from '@/constants';

const mapQualitiesAndServices = (data: any) => {
  if (data) {
    // Convert the keys of the object into an array of strings, only including ones that return true
    let attributesArray = Object.keys(data).filter((k) => data[k]);

    // Convert key strings from camelCase to Sentence case
    attributesArray = attributesArray.map((key) => {
      if (Object.prototype.hasOwnProperty.call(CAREGIVER_SERVICES, key)) {
        return upperFirst(CAREGIVER_SERVICES[key]);
      }
      if (Object.prototype.hasOwnProperty.call(CAREGIVER_QUALITIES, key)) {
        return CAREGIVER_QUALITIES[key];
      }
      if (Object.prototype.hasOwnProperty.call(MERCHANDIZED_JOB_INTERESTS, key)) {
        return upperFirst(MERCHANDIZED_JOB_INTERESTS[key]);
      }
      if (Object.prototype.hasOwnProperty.call(JOB_PREFERENCES, key)) {
        return JOB_PREFERENCES[key];
      }
      return key;
    });

    // Remove the __typename index
    attributesArray.shift();

    // Remove duplicate fields
    attributesArray = attributesArray.filter(
      (element, i) => i === attributesArray.indexOf(element)
    );

    return attributesArray;
  }
  return [];
};

export default mapQualitiesAndServices;
