import { Config, EventPayload, init, logEvent as _logEvent, getInstance } from '@care/analytics';
import getConfig from 'next/config';
import Cookies from 'universal-cookie';
import { captureException, configureScope } from '@sentry/nextjs';
import { CZEN_VISITOR_COOKIE_KEY, CZEN_SESSION_COOKIE_KEY } from '@/constants';
import { seoSegmentationListingPage_getSEOSegmentationDetails as SEOSegmentationDetailsType } from '@/__generated__/seoSegmentationListingPage';

// TODO Move below interfaces, types and enum to @care/analytics
interface Age {
  ages?: string[];
}

interface Availability {
  availability?: string;
}

interface Distance {
  milesFromZipCode?: number;
  zipCode?: string;
}

interface JobType {
  jobType: string;
}

interface Services {
  services?: string[];
}

interface Schedule {
  schedule?: string[];
}

interface YearsOfExperience {
  yearsOfExperience?: string;
}

interface PayRate {
  hourlyRate?: string;
}

interface Hours {
  hours: {
    startTime: string;
    endTime: string;
    isOvernight?: boolean;
  };
}

export interface Filters {
  filter?: string;
  feature_name: string;
  preferences?: string;
  can_help_with?: string;
  professional_skills?: string;
  languages_spoken?: string;
  min_rate?: number;
  max_rate?: number;
  number_of_children?: number;
  child_age_ranges?: string;
}

interface SeoPageViewed {
  seo_page_title: string;
  seo_page_type: string;
  service_id_for_member: string;
  page_template: string;
}

interface CTAOptions {
  cta_intent?: string;
  cta_location?: string;
  cta_text?: string;
  cta_type?: string;
}

interface ProfileViewed {
  caregiverType?: string;
}

interface TestExposure {
  test_name: string;
  test_variant: string | number | undefined;
  service_id_for_member: string;
  page_template: string;
}

interface WhenCareModal {
  asset_type: string;
  CTA: string;
  messagingContent: string;
}

interface PageProperties {
  seo_page_id: number;
  screen_name: string;
  screen_template: string;
  service_id_for_member?: string;
  slots?: string;
}

interface ArticleCarouselInteracted {}

export interface SeoFeatureInteracted {
  feature_name: string;
}

interface Event {
  data: AmplitudeEventProperties;
  name: AmplitudeEventName;
}

// TODO type any properties in HeaderData
export interface HeaderData {
  device_os: string;
  is_mobile: string | null | undefined;
  mobile_browser_version: string;
  mobile_browser: string;
}

interface ErrorViewed {
  seo_page_type?: SEOSegmentationDetailsType['pageType'];
  error_description: string;
  service_id_for_member?: string;
}

export enum WebPlatform {
  MW = 'Mobile Web',
  DT = 'Desktop Web',
}

interface AmplitudeCommonData {
  czen_session_id: SessionId;
  device_model: string;
  environment: string;
  language: string;
  os_name: string;
  os_version: string;
  referer: string;
  rendered_by: string;
  screen_name: string;
  screen_resolution: string;
  screen_template: string;
  seo_page_id: number;
  service_id_for_member?: string;
  slots?: string;
  userAgent: string;
  viewport_resolution: string;
  visitor_id: CZENVisitorId;
  web_platform: WebPlatform;
}

type AmplitudeEventName = string;
type AmplitudeEventProperties =
  | Age
  | Availability
  | CTAOptions
  | Distance
  | Filters
  | JobType
  | Hours
  | PayRate
  | ProfileViewed
  | Schedule
  | SeoPageViewed
  | Services
  | TestExposure
  | WhenCareModal
  | YearsOfExperience
  | ArticleCarouselInteracted
  | SeoFeatureInteracted
  | ErrorViewed;

type AmplitudeQueuedEvents = Event[] | [];
type SetDeviceDataFromHeaders = HeaderData | number;
type LogOrQueueEvent = Event | void;

export enum AmplitudeEvent {
  applySegmentationFilter = 'Apply Segmentation Filter',
  clickOnSegmentationFilter = 'Click on Segmentation Filter',
  ctaInteracted = 'CTA Interacted',
  providerProfileViewed = 'Provider Profile Viewed',
  monetateAssetClicked = 'Monetate Asset Clicked',
  seoPageViewed = 'SEO Page Viewed',
  testExposure = 'Test Exposure',
  jobViewed = 'Job Viewed',
  seoFeatureInteracted = 'SEO Feature Interacted',
  errorViewed = 'Error Viewed',
}

const { publicRuntimeConfig } = getConfig();

export const AMPLITUDE_DATE_FORMAT = 'MMDDYYYY';
export const AMPLITUDE_TIME_FORMAT = 'hh:mm A';

let initialized = false;

type CZENVisitorId = string;
type SessionId = string;

export class AnalyticsHelper {
  private headerData!: HeaderData;

  private pageProperties!: PageProperties;

  amplitudeEventsQueue: AmplitudeQueuedEvents = [];

  static analytics: AnalyticsHelper | undefined;

  static init(): AnalyticsHelper {
    if (this.analytics === undefined) {
      this.analytics = new AnalyticsHelper();
    }

    return this.analytics;
  }

  setHeaderData = (data: HeaderData): void => {
    this.headerData = data;
  };

  setPageProperties = (properties: PageProperties): void => {
    this.pageProperties = properties;
  };

  getHeaderData = (): HeaderData => this.headerData;

  setEventInQueue = (event: Event): void => {
    // @ts-ignore FIX: event is of never type
    this.amplitudeEventsQueue.push(event);
  };

  getEventFromQueue = (): Event => this.amplitudeEventsQueue.shift() as Event;

  getCommonData = () => {
    const cookies = new Cookies();
    const {
      device_os: deviceOs,
      is_mobile: isMobile,
      mobile_browser: mobileBrowser,
      mobile_browser_version: mobileBrowserVersion,
    } = this.headerData || {};
    const {
      seo_page_id: seoPageId,
      screen_name: screenName,
      screen_template: screenTemplate,
      service_id_for_member: ServiceIdForMember,
      slots,
    } = this.pageProperties || {};
    const czenVisitorId: CZENVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
    const czenSessionId: SessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);
    const screenResolution: string = `${window.screen.width} x ${window.screen.height}`;
    const viewportResolution: string = `${window.innerWidth} x ${window.innerHeight}`;

    const visitorId = czenVisitorId || 'VisitorIdNotFound';
    const CZENSessionId = czenSessionId || 'SessionIdNotFound';
    const deviceModel = deviceOs?.split(' ').length > 1 ? deviceOs?.split(' ')[0] : deviceOs;
    const webPlatform: WebPlatform = isMobile === 'true' ? WebPlatform.MW : WebPlatform.DT;

    const commonData: AmplitudeCommonData = {
      czen_session_id: CZENSessionId,
      device_model: deviceModel,
      environment: publicRuntimeConfig.SENTRY_ENV,
      language: navigator.language,
      os_name: mobileBrowser,
      os_version: mobileBrowserVersion,
      referer: document.referrer,
      rendered_by: 'seo-mfe',
      screen_name: screenName,
      screen_resolution: screenResolution,
      screen_template: screenTemplate,
      seo_page_id: seoPageId,
      service_id_for_member: ServiceIdForMember,
      slots,
      userAgent: navigator.userAgent,
      viewport_resolution: viewportResolution,
      visitor_id: visitorId,
      web_platform: webPlatform,
    };

    return commonData;
  };

  logEvent = (payload: EventPayload) => {
    try {
      if (!initialized) {
        const careAnalyticsConfig: Config = {
          analyticsEnabled: publicRuntimeConfig.ANALYTICS_ENABLED === 'true',
          key: publicRuntimeConfig.AMPLITUDE_API_KEY,
          commonData: this.getCommonData(),
        };

        init(careAnalyticsConfig);
        initialized = true;

        const amplitudeSessionId = getInstance()?.getSessionId();
        if (amplitudeSessionId) {
          configureScope((scope) => {
            const user = scope.getUser();
            scope.setUser({
              ...user,
              amplitudeSessionId,
            });
          });
        }
      }

      const logEventPayload = {
        name: payload.name,
        data: { ...payload.data },
      };

      _logEvent(logEventPayload);
    } catch (e) {
      captureException(e);
    }
  };
}

export const enqueEvent = (event: Event): Event => {
  const analyticsInstance = AnalyticsHelper.init();
  analyticsInstance.setEventInQueue(event);
  return event;
};

const dequeEvent = (): Event => {
  const analyticsInstance = AnalyticsHelper.init();
  return analyticsInstance.getEventFromQueue();
};

export const logOrEnqueueEvent = ({ name, data }: Event): LogOrQueueEvent | null => {
  if (!name || !data) return null;

  const analyticsInstance = AnalyticsHelper.init();

  if (analyticsInstance.getHeaderData() === undefined) {
    return enqueEvent({ name, data });
  }

  return analyticsInstance?.logEvent({
    data,
    name,
  });
};

export const trackAmplitudeEvent = (
  name: AmplitudeEventName = '',
  data: AmplitudeEventProperties = {}
): void => {
  logOrEnqueueEvent({ name, data });
};

export const setPagePropertiesFromData = (properties: PageProperties) => {
  const analyticsInstance = AnalyticsHelper.init();
  analyticsInstance?.setPageProperties(properties);
};

export const setDeviceDataFromHeaders = (data: HeaderData): SetDeviceDataFromHeaders => {
  const analyticsInstance = AnalyticsHelper.init();
  analyticsInstance?.setHeaderData(data);
  const eventsPresentInQueue = analyticsInstance.amplitudeEventsQueue.length;

  if (eventsPresentInQueue > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < eventsPresentInQueue; i++) {
      const { name: eventName, data: eventData } = dequeEvent();
      trackAmplitudeEvent(eventName, eventData);
    }

    return eventsPresentInQueue;
  }

  return data;
};
