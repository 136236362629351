import { getCaregiver_getCaregiver_member as MemberType } from '@/__generated__/getCaregiver';

interface ProfilePageSchemaProps {
  displayName: MemberType['displayName'];
  description: string;
  imageURL: string;
}

const ProfilePageSchema = ({ displayName, description, imageURL }: ProfilePageSchemaProps) => {
  const profilePageSchema = {
    '@context': 'https://schema.org',
    '@type': 'ProfilePage',
    mainEntity: {
      '@type': 'Person',
      name: displayName,
      description,
      image: imageURL,
    },
  };

  return (
    <script
      data-testid="profilePageSchema"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: JSON.stringify(profilePageSchema) }}
    />
  );
};

export { ProfilePageSchema };
