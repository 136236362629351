import Head from 'next/head';
import { ProviderMetaData } from '@/utils/getProviderMetadata';
import { MetaTags } from '../hooks/useMetaTags';

type MetaHeadProps = {
  pageTitle: ProviderMetaData['title'];
  metaTags: MetaTags;
  seoProfileUrl: string;
};

const MetaHead = ({ pageTitle, metaTags, seoProfileUrl }: MetaHeadProps) => {
  return (
    <Head>
      <title>{pageTitle}</title>
      {metaTags.map((metaTag) => (
        <meta
          key={metaTag.name}
          name={metaTag.name}
          property={metaTag.property}
          content={metaTag.content}
        />
      ))}
      <link rel="canonical" href={seoProfileUrl} />
      <link href="/img/care-no-tag.jpg" />
    </Head>
  );
};

export default MetaHead;
