import {
  getProviderDetails_getSEOSegmentationCaregiverDetails_caregiver as Caregiver,
  getProviderDetails_getSEOSegmentationCaregiverDetails_caregiver_profiles_commonCaregiverProfile as CommonCaregiverProfile,
  getProviderDetails_getSEOSegmentationCaregiverDetails_caregiver_member_address as AddressType,
} from '@/__generated__/getProviderDetails';
import { RevieweeMetrics_revieweeMetrics_RevieweeMetricsPayload_metrics as RevieweeMetricsType } from '@/__generated__/RevieweeMetrics';
import {
  getCaregiver_getCaregiver as UnifiedCaregiver,
  getCaregiver_getCaregiver_profiles_childCareCaregiverProfile as UnifiedCaregiverChildCareProfile,
  getCaregiver_getCaregiver_member_address as UnifiedCaregiverAddressType,
} from '@/__generated__/getCaregiver';
import { ActiveProfile } from '@/types/common';
import { ServiceMapShortName } from '@/constants';

type MemberAddress = Omit<AddressType, '__typename'>;
type Address = Exclude<Caregiver['member']['address'], AddressType> | MemberAddress;

type UnifiedCaregiverMemberAddress = Omit<UnifiedCaregiverAddressType, '__typename' | 'zip'>;
type UnifiedCaregiverAddress =
  | Exclude<UnifiedCaregiver['member']['address'], UnifiedCaregiverAddressType>
  | UnifiedCaregiverMemberAddress;

type CaregiverPayRange = NonNullable<ActiveProfile['payRange']>;

type MetadataProps =
  | {
      displayName: Caregiver['member']['displayName'];
      firstName: Caregiver['member']['firstName'];
      address: Address;
      yearsOfExperience: Caregiver['yearsOfExperience'];
      availability: CommonCaregiverProfile['availabilityFrequency'];
      image: string;
      serviceName: ServiceMapShortName;
      hourlyRate?: CaregiverPayRange['hourlyRateFrom']['amount'];
      bio: CommonCaregiverProfile['bio']['experienceSummary'];
      totalReviews: RevieweeMetricsType['totalReviews'];
      isPremium: UnifiedCaregiver['member']['isPremium'];
    }
  | {
      displayName: UnifiedCaregiver['member']['displayName'];
      firstName: UnifiedCaregiver['member']['firstName'];
      address: UnifiedCaregiverAddress;
      yearsOfExperience: UnifiedCaregiverChildCareProfile['yearsOfExperience'];
      availability: UnifiedCaregiverChildCareProfile['availabilityFrequency'];
      image: string;
      serviceName: ServiceMapShortName;
      hourlyRate?: CaregiverPayRange['hourlyRateFrom']['amount'];
      bio: UnifiedCaregiverChildCareProfile['bio']['experienceSummary'];
      totalReviews: RevieweeMetricsType['totalReviews'];
      isPremium: UnifiedCaregiver['member']['isPremium'];
    };

const getAvailability = (availability: MetadataProps['availability']) => {
  if (!availability) return '';
  if (availability === 'FULL_TIME') return 'Full-time';
  return 'Part-time';
};

export type ProviderMetaData = ReturnType<typeof getProviderMetadata>;

const getProviderMetadata = (data: MetadataProps) => {
  const lowerCaseServiceName = (data.serviceName as string).toLowerCase();
  const availability = getAvailability(data.availability);
  const location = data.address ? `${data.address.city}, ${data.address.state}` : null;
  const title = `Book ${data.serviceName} Services ${
    location === null ? '' : `in ${location}`
  } with ${data.displayName} ${data.hourlyRate ? `from $${data.hourlyRate}/hr` : ''} `;
  const description = `Looking to hire ${lowerCaseServiceName} services ${
    location === null ? '' : `in ${location}`
  }? Look no further! ${data.displayName} ${
    data.yearsOfExperience === null || data.yearsOfExperience === 0
      ? ''
      : `with ${data.yearsOfExperience} years of experience,`
  } offers ${lowerCaseServiceName} services starting at $${data.hourlyRate}/hr. Read reviews and contact ${data.firstName} today.`;
  const keywords = `${data.displayName}, ${availability} Caregiving Provider, ${location}`;
  const { image } = data;

  // Determine if meta robots tag should be noindex
  const { isPremium = false, bio = '', totalReviews = 0 } = data;
  const noIndexMetaTag = !isPremium && (bio?.length ?? 0) < 300 && totalReviews === 0;

  const providerMetadata = { title, description, keywords, image, noIndexMetaTag };

  return providerMetadata;
};

export default getProviderMetadata;
