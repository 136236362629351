import { ProviderMetaData } from '@/utils/getProviderMetadata';

export type MetaTags = ReturnType<typeof useMetaTags>;

const useMetaTags = (providerMetadata: ProviderMetaData) => {
  const { description, keywords, title, image, noIndexMetaTag } = providerMetadata;

  const tags = [
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
    { name: 'verify-v1', content: 'zz15lR180u9eCqTfur/9l+bMP75AampYtDEm6OnbkWY=' },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:description', content: description },
    { name: 'twitter:title', content: title },
    { name: 'twitter:site', content: '@CareDotCom' },
    { name: 'twitter:image', content: image },
    { name: 'twitter:image:alt', content: description },
    { name: 'twitter:creator', content: '@CareDotCom' },
    { name: 'og:title', property: 'og:title', content: title },
    { name: 'og:type', property: 'og:type', content: 'website' },
    { name: 'og:image', property: 'og:image', content: image },
    { name: 'og:image:width', property: 'og:image:width', content: '1200' },
    { name: 'og:image:height', property: 'og:image:height', content: '630' },
    { name: 'og:description', property: 'og:description', content: description },
    { name: 'fb:page_id', property: 'fb:page_id', content: '6030284861' },
  ];

  // Conditionally add noindex meta tag
  if (noIndexMetaTag) {
    tags.push({ name: 'robots', content: 'noindex' });
  }

  return tags;
};

export default useMetaTags;
