import { CoreVisitorGlobalHeader } from '@care/navigation';
import getConfig from 'next/config';
import { InternalLinkingMenuContentProps } from '@care/navigation/dist/components/types';
import { logOrEnqueueEvent } from '@/lib/analyticsHelper';

const {
  publicRuntimeConfig: { NEXT_PUBLIC_APP_URL },
} = getConfig();

interface SeoGlobalNavbarProps {
  enrollmentURL: string;
  internalLinkingMenuContent?: InternalLinkingMenuContentProps;
}

const SeoGlobalNavbar = ({
  enrollmentURL,
  internalLinkingMenuContent = undefined,
}: SeoGlobalNavbarProps) => {
  return (
    <CoreVisitorGlobalHeader
      baseURL={NEXT_PUBLIC_APP_URL}
      findJobURL="/app/vhp/vertical-triage?memberType=sitter"
      logAnalyticsEvent={logOrEnqueueEvent}
      joinNowURL={enrollmentURL}
      internalLinkingMenu={{
        internalLinkingMenu: true,
        internalLinkingMenuContent,
      }}
    />
  );
};

export default SeoGlobalNavbar;
